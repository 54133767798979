export default {
  data() {
    return {
      blueLabel: [
        'Diajukan',
        'Kontak Pemilik',
        'Masih ada Kamar',
        'Butuh Konfirmasi',
        'H-1 Check-out',
        'Menunggu Check-in',
      ],
      yellowLabel: [
        'Diskon',
        'Butuh Cepat',
        'Ingin Survei',
        'Tanya Alamat',
        'Hari H Survei',
        'Hari H Check-in',
        'Tunggu Pembayaran',
        'Hari H Check-out',
      ],
      greenLabel: [
        'Terbayar DP',
        'Terbayar Lunas',
        'Datang Survei',
        'Sewa Diperpanjang',
        'Sudah Check-in',
        'Survei Diterima',
      ],
      redLabel: [
        'Tidak Datang Survei',
        'Tidak Ada Konfirmasi',
        'Belum Check-out',
        'Penyewa Ajukan Berhenti Sewa',
        'Belum Check-in',
        'Belum Konfirmasi Check-out',
      ],
      inactiveLabel: [
        'Ditolak',
        'Diakhiri',
        'Dibatalkan',
        'Kedaluwarsa',
        'Sudah Check-out',
        'Dibatalkan oleh Admin',
        'Kedaluwarsa oleh Admin',
        'Belum Konfirmasi Check-in',
        'Terminated by Admin',
        'Finished',
      ],
    };
  },

  methods: {
    generateLabelType(tenantPhase) {
      const isGreenLabel = this.greenLabel
        .map(phaseStatus => phaseStatus.toLowerCase())
        .includes(tenantPhase?.toLowerCase());
      const isYellowLabel = this.yellowLabel
        .map(phaseStatus => phaseStatus.toLowerCase())
        .includes(tenantPhase?.toLowerCase());
      const isBlueLabel = this.blueLabel
        .map(phaseStatus => phaseStatus.toLowerCase())
        .includes(tenantPhase?.toLowerCase());
      const isRedLabel = this.redLabel
        .map(phaseStatus => phaseStatus.toLowerCase())
        .includes(tenantPhase?.toLowerCase());
      const isInactiveLabel = this.inactiveLabel
        .map(phaseStatus => phaseStatus.toLowerCase())
        .includes(tenantPhase?.toLowerCase());

      if (isGreenLabel) {
        return 'green';
      } else if (isYellowLabel) {
        return 'yellow';
      } else if (isBlueLabel) {
        return 'blue';
      } else if (isRedLabel) {
        return 'red';
      } else if (isInactiveLabel) {
        return 'grey';
      }

      return 'white';
    },
  },
};
